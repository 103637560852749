;(function ($) {
    const formRating = $('.reviews-form-rating')
    const formRatingStars = $('.reviews-form-rating-star')
    const formRatingInput = formRating.find('[name=stars]')

    const setRatingForStars = (rating) => {
        for (const star of formRatingStars) {
            const $star = $(star)
            const starRating = parseInt($star.data('rating'))
            if (starRating <= rating) {
                $star.removeClass('-disabled')
                continue
            }

            $star.addClass('-disabled')
        }
    }

    formRating.on('mouseleave', function () {
        const rating = parseInt(formRatingInput.val())

        setRatingForStars(rating)
    })

    formRatingStars.on('click', function () {
        formRatingInput.val($(this).data('rating') || 5)
    })

    formRatingStars.on('mouseenter', function () {
        const rating = parseInt($(this).data('rating'))

        setRatingForStars(rating)
    })
})(jQuery)