$(document).on('change', '.banner-widget select, .banner-widget input', function () {
    const form = $(this).closest('form')
    $.get(form.prop('action'), form.serialize(), function (result) {
        $('#bannerWidgetContainer').html(result)
    })
})

$(document).on('click', '.js-widget-features-link', function () {
    const el = $(this)
    const features = $('.js-widget-features')
    const isOpenFeatures = $('#isOpenFeatures')

    if (el.hasClass('--less')) {
        features.hide()
        isOpenFeatures.val(0)
        return el.removeClass('--less').find('span').html('see more options')
    }
    features.show()
    isOpenFeatures.val(1)
    return el.addClass('--less').find('span').html('see less options')
})