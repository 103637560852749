$(document).on('change', '.product-constructor-size-products select, .product-constructor-features select, .product-constructor-features input, .product-constructor-amount select, .product-constructor-amount-input', function () {
    const form = $(this).closest('form')
    $.get(form.prop('action'), form.serialize(), function (result) {
        $('#banners-constructor').html(result)
        calcGroupsAmountTotal()
    })
})

$(document).on('change', '.product-constructor select', function () {
    const self = $(this)
    const option = self.children(':selected')
    const hint = $(option).data('hint')

    hint && dialog(hint)
})

$(document).on('keydown', '.product-constructor-amount-input', function (event) {
    if (event.keyCode === 13) {
        event.preventDefault()
        const form = $(this).closest('form')
        $.get(form.prop('action'), form.serialize(), function (result) {
            $('#banners-constructor').html(result)
            calcGroupsAmountTotal()
        })
        return false
    }
})

const submitConstructorWithParams = (url) => {
    const container = $('.js-product-options')
    if (container.length) {
        const params = $('<form/>').html(container.html()).serialize()

        window.location.href = `/${url}/?${params}`
    }
}

$(document).on('click', '.js-constructor-stock', function (e) {
    e.preventDefault()

    const param = $('meta[name=csrf-param]').attr("content");
    const token = $('meta[name=csrf-token]').attr("content");

    const form = $(this).closest('form')
    form.prop({
        'action': '/cart/stock/',
        'method': 'POST'
    })
    form.append(`<input type="hidden" name="${param}" value="${token}"/>`)
    form.submit()
})

$(document).on('click', '.js-constructor-uploader', function (e) {
    e.preventDefault()

    submitConstructorWithParams('uploader')
})

$(document).on('click', '.js-constructor-designer', function (e) {
    e.preventDefault()

    submitConstructorWithParams('designer')
})

$(document).on('click', '.js-template-designer', function (e) {
    e.preventDefault()
    const container = $('.js-product-options')
    const template = $(this).data('template')
    if (template && container.length) {
        const params = $('<form/>').html(container.html()).serialize()

        window.location.href = `/designer/?tmp=${template}&${params}`
    }
})


$('.single-block .construct select').change(function () {
    const form = $(this).closest('form')
    $.getJSON('/ajax/construct-price/', form.serialize(), function (result) {
        if (result) {
            $('#price').html('$' + result)
            if ($('.size-discounts').length) {
                $.get('/ajax/discounts/', {
                    price: result,
                    product: $('[name=product]').val(),
                    prefix: 'table-'
                }, function (table) {
                    $('.size-discounts').html(table)
                })
            }
        }
    })
})

if ($('.single-block .construct').length) {
    $('.creation-link').click(function (e) {
        e.preventDefault()

        submitFormToLink(this)
    })
}