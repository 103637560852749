$(document).on('change', '.product-options-container select', function () {
    $(this).closest('form').submit()
})

$(document).on('submit', '#banners-options form:not(.self-submit)', function (e) {
    e.preventDefault()
    var form = $(this)
    $.get(form.prop('action'), form.serialize(), function (result) {
        if (result) {
            $('#banners-options').html(result)
        }
    })
})