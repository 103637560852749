document.addEventListener('DOMContentLoaded', function () {
    const submitButton = document.getElementById('card-field-submit-button');
    if (!submitButton) {
        return;
    }

    if (!window.paypal || !window.paypal.CardFields || !window.paypal.Buttons) {
        return error('Payment SDK not loaded. Please try again later or use another browser');
    }

    const confirmCheckbox = document.getElementById('js-confirm');

    const csrf = {
        [$('meta[name="csrf-param"]').attr('content')]: $('meta[name="csrf-token"]').attr('content')
    }

    function logCheckoutEvent(action, data = {}) {
        $.post('/ajax/logger/', {
            ...csrf,
            event: 'checkout',
            action,
            data
        })
    }

    function triggerError(message) {
        error(message);

        submitButton.disabled = false;
        submitButton.innerText = 'Pay now';
    }

    function handlePayPalError(err) {
        error('Error processing order payment. Please try again.');

        logCheckoutEvent('error', {
            message: err.message || 'Error in PayPal SDK'
        })

        setTimeout(() => {
            window.location.reload();
        }, 2000)
    }

    function createOrderPayment() {
        return new Promise((resolve, reject) => {
            $.post('/checkout/payment/', csrf)
                .done((res) => resolve(res.id))
                .fail((xhr, status, error) => reject(error))
        })
    }

    function approveOrderPayment(data) {
        $.post("/checkout/approve/", {
            ...csrf,
            orderId: data.orderID
        }).then((res) => {
            if (res.error) {
                error(res.error);

                return setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }

            window.location.assign('/checkout/receipt/?order=' + res.order);
        });
    }

    window.paypal
        .Buttons({
            style: {
                shape: "pill",
                layout: "vertical",
                color: "silver",
                label: "buynow"
            },
            onClick: function (data, actions) {
                logCheckoutEvent('paypal_button_click');

                if (!confirmCheckbox.checked) {
                    error('Please agree to our Terms & Conditions');
                    return actions.reject();
                }
                return actions.resolve();
            },
            createOrder: createOrderPayment,
            onApprove: approveOrderPayment,
            onCancel: () => {
                window.location.assign("/checkout/billing/");
            },
            onError: handlePayPalError
        }).render("#paypal-button-container");

    const cardStyle = {
        'input': {
            'font-size': '13px',
            'font-family': 'Roboto, sans-serif',
            'color': '#3a3a3a',
            'border': '1px solid #d7d7d7',
            'border-radius': '4px',
            'padding': '10px 15px',
            'line-height': 'normal'
        }
    };

    const cardFields = window.paypal
        .CardFields({
            style: cardStyle,
            createOrder: createOrderPayment,
            onApprove: approveOrderPayment,
            onError: handlePayPalError
        });

    if (cardFields.isEligible()) {
        cardFields.NameField({placeholder: 'Cardholder Name'}).render("#card-name-field-container");
        cardFields.NumberField().render("#card-number-field-container");
        cardFields.CVVField().render("#card-cvv-field-container");
        cardFields.ExpiryField().render("#card-expiry-field-container");

        function submitCardFields(e) {
            logCheckoutEvent('submit_card_fields');

            submitButton.disabled = true;
            submitButton.innerText = 'Processing...';


            cardFields.getState().then((state) => {
                if (!state.isFormValid) {
                    return triggerError('Please fill out all fields');
                }
                if (!confirmCheckbox.checked) {
                    return triggerError('Please agree to our Terms & Conditions');
                }
                const billingAddress = {
                    addressLine1: document.getElementById('card-billing-address-line-1').value,
                    addressLine2: document.getElementById('card-billing-address-line-2').value,
                    region: document.getElementById('card-billing-address-state').value,
                    locality: document.getElementById('card-billing-address-city').value,
                    postalCode: document.getElementById('card-billing-address-postal-code').value,
                    countryCode: 'US'
                }

                for (const field of Object.keys(billingAddress)) {
                    if (!billingAddress[field] && field !== 'addressLine2') {
                        return triggerError('Please fill out all fields');
                    }
                }

                cardFields.submit({billingAddress})
            })
        }

        document
            .getElementById('card-field-submit-button')
            .addEventListener('click', submitCardFields);

        window.addEventListener("error", function (e) {
            logCheckoutEvent('error', {
                message: e.message,
                filename: e.filename,
                lineno: e.lineno,
                colno: e.colno
            })

            return false;
        })
    }
})