;(function ($) {
    const loadButton = $('.js-load-reviews')
    const reviewsSortOrder = $('#reviewsSortOrder')
    const reviewsContainer = $('.reviews-container')
    const reviewsPerPage = parseInt(loadButton.data('limit') ?? 6)

    const loadReviews = (replace = false) => {
        const product = loadButton.data('product')

        const page = parseInt(loadButton.data('page') ?? 1) + 1

        loadButton.data('page', page)
        loadButton.prop('disabled', true)

        const callback = (result) => {
            loadButton.prop('disabled', false)
            const {reviews} = result

            reviewsContainer.html(
                replace ? reviews.join('') : reviewsContainer.html() + reviews.join('')
            )

            if (reviews.length < reviewsPerPage) {
                return loadButton.hide()
            }
            loadButton.show()
        }

        $.get(`/review/search/?page=${page}&product=${product}&sort=${reviewsSortOrder.val()}`, callback)
    }

    loadButton.on('click', function () {
        loadReviews()
    })

    reviewsSortOrder.on('change', function () {
        loadButton.data('page', 0)

        loadReviews(true)
    })

    reviewsContainer.on('mouseenter', '.reviews-item-photos-item', function () {
        const id = $(this).data('image')

        $(`#reviewImagePhoto${id}`).css('display', 'flex')
    }).on('mouseleave', '.reviews-item-photos-item', function () {
        const id = $(this).data('image')

        $(`#reviewImagePhoto${id}`).hide()
    })
})(jQuery)