;(function ($) {
    var STORE_FILES_URL = '/uploader/store-files/',
        REMOVE_FILE_URL = '/uploader/remove-file/',
        MAX_UPLOAD_SIZE = 100 * 1024 * 1024

    var items = {},
        root = '.bulk-upload ',
        linkInput = $(root + '.link-upload input:text'),
        linkSubmit = $(root + '.link-upload button'),
        fileSelect = $(root + 'input:file'),
        dropBox = $(root + '.drop-files'),
        selectedItems = $(root + '.selected-files'),
        uploadedItems = $(root + '.upload-ready-items'),
        uploadedItemsAmount = $(root + '.upload-ready-items input.qnt'),
        addAllItemsBtn = $(root + '.js-all-items'),
        startUploadBtn = $(root + '.start-upload'),
        uploadStatus = $(root + ' .upload-status'),
        readyItems = $(root + '.upload-ready-items'),
        allowedExtensions = ['jpg', 'png', 'jpeg', 'tiff', 'tif', 'eps', 'pdf', 'psd', 'ai'],
        itemTemplate = '<tr class="item"><td>%file%</td><td colspan="2"><div class="progress-bar hide"><div style="width: 0"></div></div><span>&nbsp;</span><a href="javascript:void(0)" class="f-right js-remove" data-name="%file%"><img src="/img/uploader/remove-item.png"/></a></td></tr>'

    function stopDefaultEvents(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function toggle() {
        (function toggleContainer() {
            var qnt = selectedItems.find('tr').length
            if (qnt) {
                return selectedItems.show()
            }
            return selectedItems.hide()
        })();
        (function toggleUploadedItems() {
            var qnt = uploadedItems.find('tr').length
            if (qnt) {
                return uploadedItems.show()
            }
            return uploadedItems.hide()
        })();
        (function toggleUploadBtn() {
            startUploadBtn.hide()
            $.each(items, function () {
                startUploadBtn.show()
            })
        })();
        (function toggleDropBox() {
            dropBox.removeClass('js-busy')
            fileSelect.prop('disabled', false)
            $.each(items, function () {
                if (this.uploading) {
                    dropBox.addClass('js-busy')
                    fileSelect.prop('disabled', 'disabled')
                }
            })
        })();
        (function toggleUploadStatus() {
            uploadStatus.hide()
            $.each(items, function () {
                if (this.uploading) {
                    return uploadStatus.show()
                }
            })
        })();
        (function toggleSubmitBtn() {
            addAllItemsBtn.parent().hide()
            if (uploadedItems.find('tr').length) {
                addAllItemsBtn.parent().show()
            }
        })()
    }

    function toggleLinkSubmit(e) {
        linkSubmit.prop('disabled', 'disabled')
        if ($(e.target).val().length) {
            linkSubmit.removeProp('disabled')
        }
    }

    function onAmountChanged(e) {
        var el = e.target
        if (!el.min) return
        if (parseInt(el.value) < parseInt(el.min)) {
            el.value = el.min
        }
    }

    function addAllCartItems() {
        var self = $(this)
        setTimeout(function () {
            self.attr('disabled', 'disabled')
        }, 100)
    }

    function addSelectedFile(e) {
        var files = e.target.files.length ? e.target.files : e.originalEvent.dataTransfer.files
        $.each(files, function () {
            if (items[this.name] !== undefined) {
                return
            }
            var ext = this.name.split('.').pop().toLowerCase()
            if (allowedExtensions.indexOf(ext) === -1) {
                return error(this.name + ': Unsupported file format. Allowed formats - ' + allowedExtensions.join(', '))
            }
            if (this.size > MAX_UPLOAD_SIZE) {
                return error(this.name + ': File too large. Maximum file size is 100mb. Please submit a smaller file or a cloud link instead')
            }
            var item = {file: this, template: $(itemTemplate.replace(/%file%/g, this.name)), uploading: false}
            items[this.name] = item
            selectedItems.find('.upload-items').append(item.template)
        })
        fileSelect.val('')
        toggle()
    }

    function removeSelectedFile() {
        var inputs = $(this).closest('tr').find('input:hidden')
        inputs.each(function () {
            $.post(REMOVE_FILE_URL, {id: $(this).val()})
        })
        var name = $(this).data('name')
        if (name) {
            delete items[name]
        }
        $(this).closest('tr').remove()
        toggle()
    }

    function startUpload() {
        var queue = [], i = 0, max = 5, self = $(this)

        uploadStatus.show()
        startUploadBtn.hide()
        dropBox.addClass('js-busy')
        fileSelect.prop('disabled', 'disabled')

        function process(item) {
            var progress = item.template.find('.progress-bar'),
                removeBtn = item.template.find('.js-remove'),
                statusBoard = progress.next('span')

            if (item.uploading) {
                return
            }

            function loadStart() {
                progress.show()
                removeBtn.hide()
                item.uploading = true
            }

            function loadEnd() {
                progress.hide()
                removeBtn.show()
                item.uploading = false

                toggle()
            }

            function loadProgress(e) {
                var percent = (100 / e.total) * e.loaded,
                    progressBar = progress.find('div')

                progressBar.animate({'width': percent + '%'}, 100)
                if (percent > 99) {
                    statusBoard.html('Generating preview')
                }
            }

            function loadComplete() {
                if (xhr.readyState !== 4) {
                    return
                }
                if (xhr.status !== 200) {
                    return statusBoard.html('Failed to store design file')
                }
                if (queue.length > 0) {
                    process(queue.shift())
                }
                const response = JSON.parse(xhr.responseText)
                delete items[item.file.name]

                let preview = response.preview
                if (preview) {
                    const amount = getQueryParam('amount')
                    if (amount) {
                        preview = preview.replace('[amount]" value="1"', `[amount]" value="${amount}"`)
                    }
                    readyItems.append(preview)
                }
                item.template.remove()
            }

            var xhr = new XMLHttpRequest()
            xhr.addEventListener('loadstart', loadStart)
            xhr.addEventListener('readystatechange', loadComplete)
            xhr.addEventListener('loadend', loadEnd)
            xhr.upload.addEventListener('progress', loadProgress)


            var data = new FormData()
            data.append('front', item.file)
            data.append('product', self.data('product'))
            if (window.innerWidth > 900) {
                data.append('showProofButton', '1')
            }

            xhr.open('POST', STORE_FILES_URL)
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            xhr.send(data)
        }

        $.each(items, function () {
            i < max ? process(this) : queue.push(this)
            ++i
        })
    }

    function loadFileProof() {
        disable(this)
        const id = $(this).data('id')
        const pixelRatio = getDevicePixelRatio()
        $.post('/uploader/proof/', {id, pixelRatio}, (res) => {
            enable(this)

            res.content && dialog(res.content)

            if (res.error) {
                error(res.error)
                hide(this)
            }
        })
    }

    fileSelect.on('change', addSelectedFile)
    addAllItemsBtn.on('click', addAllCartItems)
    startUploadBtn.on('click', startUpload)
    linkInput.on('keyup', toggleLinkSubmit)
    uploadedItems.on('change', 'input.qnt', onAmountChanged)

    $(root).on('click', '.js-remove', removeSelectedFile)
        .on('click', '.get-file-proof', loadFileProof)

    dropBox.on('drag dragstart dragend dragover dragenter dragleave drop', stopDefaultEvents)
    dropBox.on('drop', addSelectedFile)
})(jQuery)