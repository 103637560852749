const loaderOverlay = $('<div class="product-customizer-overlay"></div>')


$(document).on('change', '.product-customizer-features input, .product-customizer-features select, .product-customizer-amount-input', function (e) {
    e.preventDefault()

    const form = $(this).closest('form')
    $('#productCustomizer').append(loaderOverlay)
    $.get(form.prop('action'), form.serialize(), function (result) {
        $('#productCustomizer').html(result)
    })
})

$(document).on('click', '.product-customizer-delivery-submit', function (e) {
    e.preventDefault()

    const form = $(this).closest('form')

    const params = {
        zip: $('.product-customizer-delivery-zip').val(),
        qnt: $('.product-customizer-delivery-qnt').find('select,input').val(),
        size: form.find('input[name=size]').val(),
        product: form.find('input[name=product]').val()
    }

    const submitBtn = $(e.target)
    const resultContainer = $('.product-customizer-delivery-result')
    const flushToDefaultState = () => {
        resultContainer.html('')
        submitBtn.removeAttr('disabled')
    }

    submitBtn.attr('disabled', true)
    $.post('/shipping/product-size-zip-rates/', params, (result) => {
        flushToDefaultState()
        const table = $('<table>')
        for (const {caption, price, eta} of result) {
            table.append(`<tr><td>${caption}</td><td>${eta}</td><td>${price}</td></tr>`)
        }
        resultContainer.append(table)
    }).fail(flushToDefaultState)
})

$(document).on('click', '.product-customizer-features-item label', (e) => {
    const slideId = $(e.target).data('slider')

    slideId && showSelectedSlide(slideId)
})

$(document).on('click', '.js-customizer-product', function (e) {
    e.preventDefault()

    const container = $('#productCustomizer')

    const page = $(this).data('page')
    const product = $(this).data('product')
    const template = $(document).find('input[name=template]').val()

    const url = new URL(window.location.href)
    url.pathname = '/banner-customizer/'
    page && url.searchParams.append('page', page)
    product && url.searchParams.append('product', product)
    template && url.searchParams.append('template', template)

    container.append(loaderOverlay)
    $.get(url.toString(), function (result) {
        container.html(result)
    })
})

$(document).on('click', '.js-customizer-theme', function (e) {
    e.preventDefault()

    const theme = $(this).data('theme')
    const link = $('.product-templates-themes-item[data-theme=' + theme + ']')

    link.length && link.trigger('click')
})

const customThemeGroups = $('#customThemeGroups')
const customGroupThemes = $('#customGroupThemes')
const customThemeTemplates = $('#customThemeTemplates')

function convertTemplatesToHtml(templates) {
    return templates.map((template) => getTemplateHtml(template)).join('')
}

if (customThemeGroups.length) {
    $.get('/ajax/templates/?perPage=9&product=13', function ({templates}) {
        customThemeTemplates.html(convertTemplatesToHtml(templates))
    })
    customGroupThemes.on('change', function (e) {
        const theme = $(this).val()
        if (!theme) return;

        $.get('/ajax/templates/?perPage=9&theme=' + theme, function ({templates}) {
            customThemeTemplates.html(convertTemplatesToHtml(templates))
        })
    })
    customThemeGroups.on('change', function (e) {
        const allThemes = groupThemes[$(this).val()] || [{id: '', caption: 'SELECT'}]
        customGroupThemes.find('option').remove()
        for (let i in allThemes) {
            customGroupThemes.append('<option value="' + allThemes[i].id + '">' + allThemes[i].caption + '</option>')
        }
        customGroupThemes.val(allThemes[0].id).trigger('change')
    })
    for (let i in allGroups) {
        customThemeGroups.append('<option value="' + allGroups[i].id + '">' + allGroups[i].caption + '</option>')
    }
}