;(function ($) {
    var STORE_FILES_URL = '/uploader/store-files/',
        REMOVE_FILE_URL = '/uploader/remove-file/',
        MAX_UPLOAD_SIZE = 100 * 1024 * 1024

    var selectedFiles = {},
        root = '.single-upload ',
        front = $(root + '.front-file'),
        back = $(root + '.back-file'),
        dropBox = $(root + '.drop-files'),
        frontName = front.find('.drop-files-file'),
        backName = back.find('.drop-files-file'),
        frontFile = front.find('input:file'),
        backFile = back.find('input:file'),
        addAllItemsBtn = $(root + '.js-all-items'),
        progressBar = $(root + '.progress-bar div'),
        uploadHelp = $(root + '.file-upload-help'),
        uploadStatus = $(root + '.upload-status'),
        readyItems = $(root + '.upload-ready-items'),
        startUploadBtn = $(root + '.file-upload .start-upload'),
        allowedExtensions = ['jpg', 'png', 'jpeg', 'tiff', 'tif', 'eps', 'pdf', 'psd', 'ai']

    function toggle() {
        (function toggleReadyItems() {
            readyItems.hide()
            addAllItemsBtn.parent().hide()
            if (readyItems.find('tr').length) {
                readyItems.show()
                addAllItemsBtn.parent().show()
            }
        })();
        (function toggleFrontIcon() {
            front.removeClass('js-ready')
            frontName.html('')
            if (selectedFiles.front) {
                front.addClass('js-ready')
                frontName.html(selectedFiles.front.name)
            }
        })();
        (function toggleBackIcon() {
            back.removeClass('js-ready')
            backName.html('')
            if (selectedFiles.back) {
                back.addClass('js-ready')
                backName.html(selectedFiles.back.name)
            }
        })();
        (function toggleStartUploadBtn() {
            var isBackFile = selectedFiles.back
            if (selectedFiles.front && (!back.length || isBackFile)) {
                startUploadBtn.show()
                return startUploadBtn.removeAttr('disabled')
            }
            startUploadBtn.hide()
            startUploadBtn.attr('disabled', 'disabled')
        })()
    }

    function stopDefaultEvents(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function handleUploadStart() {
        front.addClass('js-busy')
        frontFile.attr('disabled', 'disabled')

        back.addClass('js-busy')
        backFile.attr('disabled', 'disabled')

        uploadHelp.hide()
        uploadStatus.show()
    }

    function handleUploadFinish() {
        selectedFiles = {}

        front.removeClass('js-busy')
        frontFile.val('').removeAttr('disabled')

        back.removeClass('js-busy')
        backFile.val('').removeAttr('disabled')

        uploadHelp.show()
        uploadStatus.hide()

        toggle()
    }

    function isValidExt(file) {
        var ext = file.name.split('.').pop().toLowerCase()

        return allowedExtensions.indexOf(ext) !== -1
    }

    function isValidSize(file) {
        return file.size && file.size < MAX_UPLOAD_SIZE
    }

    function addAllCartItems() {
        var self = $(this)
        setTimeout(function () {
            self.attr('disabled', 'disabled')
        }, 100)
    }

    function onAmountChanged(e) {
        var el = e.target
        if (!el.min) return
        if (parseInt(el.value) < parseInt(el.min)) {
            el.value = el.min
        }
    }

    function setSelectedFile(e) {
        var files = e.target.files && e.target.files.length ? e.target.files
                : (e.originalEvent.dataTransfer ? e.originalEvent.dataTransfer.files : []),
            side = $(e.target).prop('id')
                || $(e.target).closest('.drop-files').prop('id'),
            file = files[0]
        selectedFiles[side] = null
        if (file) {
            if (!isValidExt(file)) {
                return error(file.name + ': Unsupported file format. Allowed formats - ' + allowedExtensions.join(', '))
            }
            if (!isValidSize(file)) {
                return error('File too large. Maximum file size is 100mb. Please submit a smaller file or a cloud link instead')
            }
            selectedFiles[side] = file
        }
        toggle()
    }

    function removeUploadedFiles() {
        var inputs = $(this).closest('tr').find('input:hidden')
        inputs.each(function () {
            $.post(REMOVE_FILE_URL, {id: $(this).val()})
        })
        $(this).closest('tr').remove()
        toggle()
    }

    function uploadUserFiles() {
        function loadProgress(e) {
            var percent = (100 / e.total) * e.loaded

            progressBar.animate({'width': percent + '%'}, 50)
        }

        function loadComplete() {
            if (xhr.readyState !== 4 || xhr.status !== 200) {
                return
            }
            const response = JSON.parse(xhr.responseText)
            let preview = response.preview
            if (preview) {
                const amount = getQueryParam('amount')
                if (amount) {
                    preview = preview.replace('[amount]" value="1"', `[amount]" value="${amount}"`)
                }
                readyItems.append(preview)
            }
            if (response.error) {
                error(response.error)
            }
            toggle()
        }

        handleUploadStart()

        var xhr = new XMLHttpRequest()
        xhr.addEventListener('loadend', handleUploadFinish)
        xhr.addEventListener('readystatechange', loadComplete)
        xhr.upload.addEventListener('progress', loadProgress)

        var data = new FormData()
        data.append('front', selectedFiles.front)
        if (selectedFiles.back) {
            data.append('back', selectedFiles.back)
        }
        data.append('product', $(this).data('product'))
        if (window.innerWidth > 900) {
            data.append('showProofButton', '1')
        }

        xhr.open('POST', STORE_FILES_URL)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.send(data)
    }

    function loadFileProof() {
        disable(this)
        const id = $(this).data('id')
        const pixelRatio = getDevicePixelRatio()
        $.post('/uploader/proof/', {id, pixelRatio}, (res) => {
            enable(this)

            res.content && dialog(res.content)

            if (res.error) {
                error(res.error)
                hide(this)
            }
        })
    }

    $(root)
        .on('change', 'input:file', setSelectedFile)
        .on('click', '.js-remove', removeUploadedFiles)
        .on('change', 'input.qnt', onAmountChanged)
        .on('click', '.get-file-proof', loadFileProof)

    addAllItemsBtn.on('click', addAllCartItems)
    startUploadBtn.on('click', uploadUserFiles)

    dropBox.on('drag dragstart dragend dragover dragenter dragleave drop', stopDefaultEvents)
    dropBox.on('drop', setSelectedFile)
})(jQuery)