;(function ($) {
    function reloadBannerOptions() {
        $("#banners-options form").submit();
    }

    if ($("input[name=width]").length) {
        $(".product-custom-size").on("change", "select:not([name=package]), input:not([name=units],[name=height],[name=width],[name=amount])", reloadBannerOptions);
    }

    $(".product-constructor-custom-size-units").on("change", "input[name=units]", function () {
        var $w = $("input[name=width]"), $h = $("input[name=height]");
        if ($(this).val() == "feet") {
            $w.val($w.val() < 12 ? 1 : Math.round($w.val() / 12));
            $h.val($h.val() < 12 ? 1 : Math.round($h.val() / 12));
            return true;
        }
        $w.val($w.val() >= 1 ? $w.val() * 12 : 1);
        $h.val($h.val() >= 1 ? $h.val() * 12 : 1);
    });

    function checkStepValue(e) {
        var val = $(this).val(),
            units = $("input[name=units]:checked").length > 0 ? $("input[name=units]:checked") : $("input[name=units]"),
            step = window.sizeConfig ? window.sizeConfig.step : null;
        if (!step) {
            return
        }
        if (step == 1 || units.val() == "feet") {
            return $(this).val(Math.floor(val))
        }
        if (val.indexOf(".") == -1) {
            return;
        }
        var fraction = val - Math.floor(val),
            result = (step * Math.ceil(fraction / step));

        $(this).val((Math.floor(val) + result).toFixed(3));
    }

    function checkSizeValues(e) {
        var width = $("input[name=width]"),
            height = $("input[name=height]"),
            units = ($("input[name=units]:checked").length > 0 ? $("input[name=units]:checked") : $("input[name=units]")).val(),
            config = window.sizeConfig || null;
        if (!config) {
            return
        }
        const widthValue = width.val().replaceAll(/[^\d.]/g, '')
        const heightValue = height.val().replaceAll(/[^\d.]/g, '')
        if (widthValue !== width.val()) {
            width.val(widthValue)
        }
        if (heightValue !== height.val()) {
            height.val(heightValue)
        }
        if (width.val() < config[units].width.min) {
            width.val(config[units].width.min)
        }
        if (height.val() < config[units].height.min) {
            height.val(config[units].height.min)
        }
        var min = Math.min(config[units].width.max, config[units].height.max),
            max = Math.max(config[units].width.max, config[units].height.max);

        if (width.val() < min && height.val() < min) {
            return
        }
        if (width.val() > max && height.val() > max) {
            width.val(config[units].width.max);
            return height.val(config[units].height.max)
        }

        if (width.val() > min) {
            if (height.val() > min) return width.val(min);
            if (width.val() > max) return width.val(max);
        } else {
            if (width.val() > min) return height.val(min);
            if (height.val() > max) return height.val(max);
        }
    }

    function onSizeChanged(e) {
        checkStepValue.call(this);
        checkSizeValues.call(this);
        reloadBannerOptions()
    }

    $(document).on("change", ".js-custom-size [name=width]", onSizeChanged);
    $(document).on("change", ".js-custom-size [name=height]", onSizeChanged);
    $(document).on("change", ".product-custom-quantity [name=amount]", function (e) {
        var min = $(this).prop('min'), val = $(this).val();
        if (parseInt(val) < parseInt(min)) {
            $(this).val(min)
        }
        return onSizeChanged.call(this)
    });

})(jQuery);