;(function ($) {
    var STORE_LINK_URL = '/uploader/store-link-design/',
        ADD_TO_CART_URL = '/cart/add/'

    $(document).ready(function () {
        var linkUpload = $('.link-upload'),
            linkUploadBtn = linkUpload.find('button'),
            linkUploadInput = linkUpload.find('input[type=text]')

        function toggleLinkSubmit() {
            if (linkUploadInput.val()) {
                return linkUploadBtn.removeAttr('disabled')
            }
            linkUploadBtn.attr('disabled', 'disabled');
        }

        linkUploadInput.on('keyup', toggleLinkSubmit)

        linkUploadBtn.on('click', function () {
            var options = $('#designParams').find('input').serialize(),
                params = options + '&' + $.param({link: linkUploadInput.val()})

            function handleSuccessRequest(data) {
                if (data.error) {
                    error(data.error)
                }
                if (data.design) {
                    window.location.href = ADD_TO_CART_URL + '?design=' + data.design.id
                }
            }

            $.post(STORE_LINK_URL, params, handleSuccessRequest)
        })
    })
})(jQuery);