const searchTemplate = (item) => {
    return `<div><a href="${item.url}"><img src="${item.icon}" srcset="${item.icon2x} 2x" width="280" height="280" alt=""></a><img src="/img/cbut.png" srcset="/img/cbut@2x.png 2x" width="92" height="21" alt=""></div>`
}

const productTemplate = (item, place = 'product') => {
    return getTemplateHtml(item, place)
}

const themeTemplates = (item) => {
    return productTemplate(item, 'theme')
}

const loadTemplates = (url, container, templateFunc) => {
    const moreBtn = $('.js-more-templates')

    moreBtn.data('source', url).data('page', 2).hide()

    container.html('')

    const callback = (result) => {
        const {meta, templates} = result
        if (!templates) {
            return
        }
        for (const template of templates) {
            container.append(
                templateFunc.call(this, template)
            )
        }
        if (meta.pageCount > 1) {
            moreBtn.show()
        }
    }

    $.get(url, callback)
}

$('.js-more-templates').on('click', function () {
    const self = $(this)
    const page = self.data('page') || 2
    const container = $(self.data('container') || '.templates .items')
    const templateFunc = (self.data('template') || 'search') + 'Template'
    const url = self.data('source') || `/ajax/templates/?product=${self.data('product')}`

    self.attr('disabled', true)
    $.get(url, {page}, (result) => {
        self.attr('disabled', false)
        if (!result) {
            return
        }
        const {meta, templates} = result
        for (const template of templates) {
            container.append(
                window[templateFunc](template)
            )
        }
        if (page >= meta.pageCount) {
            return self.hide()
        }
        self.data('page', page + 1)
    })
})

$('.search-facet-products li').on('click', function () {
    const self = $(this)
    const query = $('input[name=q]')
    const container = $('.templates .items')
    const url = `/ajax/templates/?q=${query.val()}&product=${self.data('product')}`

    self.parent().find('li').removeClass('active')
    self.addClass('active')

    loadTemplates(url, container, searchTemplate)
})

$('.product-templates-themes-item').on('click', function () {
    const self = $(this)
    const container = $('.product-templates-list')
    const url = `/ajax/templates/?theme=${self.data('theme')}`

    $('.product-templates-themes-item').removeClass('active')
    self.addClass('active')

    loadTemplates(url, container, productTemplate)
})

$('.product-templates-groups-item').on('click', function () {
    const self = $(this)
    const container = $('.product-templates-list')
    const url = `/ajax/templates/?group=${self.data('group')}`

    $('.product-templates-groups-item').removeClass('active')
    self.addClass('active')

    loadTemplates(url, container, productTemplate)
})

$('.product-templates-search').on('submit', function (e) {
    e.preventDefault()

    const self = $(this)
    const container = $('.product-templates-list')
    const url = `/ajax/templates/?${self.serialize()}`

    $('.product-templates-themes-item').removeClass('active')
    $('.product-templates-facets-item').removeClass('active')

    loadTemplates(url, container, productTemplate)
})

$('.theme-templates-search').on('submit', function (e) {
    e.preventDefault()

    const self = $(this)
    const container = $('.theme-templates-list')
    const url = `/ajax/templates/?${self.serialize()}`

    loadTemplates(url, container, themeTemplates)
})

$(document).on('click', '.js-params-link', (e) => {
    e.preventDefault()
    let url = e.currentTarget.href
    const options = $('.js-product-options')
    if (options.length) {
        const separator = url.indexOf('?') === -1 ? '?' : '&'
        url += separator + options.serialize()
    }
    window.location.href = url
})

const setTransformStyle = (container, angle) => {
    container.style.webkitTransform = `rotateY(${angle}deg)`
    container.style.MozTransform = `rotateY(${angle}deg)`
    container.style.msTransform = `rotateY(${angle}deg)`
    container.style.OTransform = `rotateY(${angle}deg)`
    container.style.transform = `rotateY(${angle}deg)`
}

$(document).on('click', '.js-flip-icon', function () {
    const container = $(this).parent().children(':first')[0]
    if (!container.style.transform) {
        return setTransformStyle(container, 180)
    }

    const angle = parseInt(
        container.style.transform.replaceAll(/\D/g, '')
    )
    return setTransformStyle(container, angle + 180)
})